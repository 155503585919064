import "../styles/globals.css";
import { EditorProvider, SiteProvider } from "@wazoe/elements";

function MyApp({ Component, pageProps }) {
  return (
    <EditorProvider initMode="production" backendApi={pageProps.backendApiUrl}>
      <SiteProvider
        initLocale={pageProps?.locale}
        defaultLocale={pageProps.site?.defaultLanguage?.code}
        pages={pageProps?.site?.pages}
        forms={pageProps?.site?.forms}
        menus={pageProps?.site?.menus}
        languages={pageProps?.site?.languages}
        activePage={
          pageProps?.pageData?.path === "/"
            ? `/${pageProps?.locale?.toLowerCase()}`
            : `/${pageProps?.locale?.toLowerCase()}${pageProps?.pageData?.path}`
        }
      >
        <Component {...pageProps} />
      </SiteProvider>
    </EditorProvider>
  );
}

export default MyApp;
